import React from 'react';
import { Box, Image, Heading, Text, Flex, Icon } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import ArticleMenu from './ArticleMenu';
import ArticlePreview from './ArticlePreview';
import ArticlesButtons from './ArticlesButtons';
import formatCreatedAtDate from '../Date';
import { ArticleContentProps } from '../../types/interface';
import extractFirstImage from '../ExtractedFirstImage';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';



const ArticleList: React.FC<ArticleContentProps> = ({ articles, isAuthenticated, handleArticleClick, updateArticleData }) => {
  return (
    <>
      {articles.map((article) => {
        const firstImageUrl = extractFirstImage(article.htmlContent);

        return (
          <Box
            key={article._id}
            p={4}
            // borderWidth="1px"
            // borderRadius="lg"
            mb={4}
            // padding={10}
            display="flex"
            flexDirection={{ base: 'column', sm: 'row' }}
            gap={10}
            onClick={() => handleArticleClick(article._id)}
          >
            <Box flexBasis="30%">
              {firstImageUrl && (
                <Image
                  src={firstImageUrl}
                  alt={`Banner Image for ${article.title}`}
                  mb={2}
                  width="100%"
                  height={500}
                  objectFit="cover"
                  borderRadius="lg"
                />
              )}
            </Box>

            <Box flexBasis="70%" pr={4}>
              <Flex justify="space-between" alignItems="center">
                <Box display="block" alignItems="start">
                <Flex alignItems="center">
                <Heading
                    as="h2"
                    size="xl"
                    mb={4}
                    fontSize={['24px', '28px', '32px', '36px']} // Responsive font sizes for different breakpoints
                  >
                    {article.title}
                  </Heading>
                    <Icon
                      as={article.verify ? FaCheckCircle : FaTimesCircle}
                      color={article.verify ? 'green.500' : 'red.500'}
                      fontSize={20}
                      cursor="pointer"
                      ml={2} // Adjust margin to provide spacing between the title and icon
                    />
                  </Flex>
                  <Text
                    textAlign="start"
                    paddingBottom="5px"
                    fontFamily="Inter"
                    paddingTop="5px"
                    fontWeight={600}
                    fontSize={16}
                  >
                    {isAuthenticated ? (
                      <Link to={`/user/${article.user._id}`}>@{article.user.username}</Link>
                    ) : (
                      <Link to="/login" />
                    )}
                  </Text>
                  <Text
                    paddingBottom="5px"
                    textAlign="start"
                    fontFamily="Inter"
                    fontWeight={600}
                    fontSize={16}
                  >
                    {formatCreatedAtDate(article.createdAt)}
                  </Text>
                </Box>
                {isAuthenticated && article.user ? (
                  <ArticleMenu articleId={article._id} ownerId={article.user._id} />
                ) : (
                  <Link to="/login" />
                )}
              </Flex>
              <ArticlePreview htmlContent={article.htmlContent} />
              <ArticlesButtons article={article} updatedArticleData={updateArticleData} />
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default ArticleList;
