import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../types/interface";
import { createSelector } from "@reduxjs/toolkit";
import { selectAccessToken } from "../slice/userSlice";
import { articlesAdapter } from "../slice/article";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const initialState = articlesAdapter.getInitialState([]);

export const articleApiSlice = createApi({
  reducerPath: 'articles',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState | undefined;
      const accessToken = state ? selectAccessToken(state) : '';
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      headers.set('Content-Type', 'application/json');
      headers.set('Access-Control-Allow-Origin', '*');
    },
  }),
  
  tagTypes: [ 'Articles' ],
  endpoints: (builder) => ({
    getArticles: builder.query({
      query: () => `/articles`,
      providesTags: ['Articles'],
    }),
    getArticleById: builder.query({
      query: (articleId) => {
        return {
          url:`/articles/${articleId}`,
          invalidatesTags: [{ type: "Articles"}],
        };
      }
    }),
    createArticles: builder.mutation({
      query: ({ userId, body }) => {
        return {
          url: `users/${userId}/articles`,
          method: 'POST',
          body: body,        
          invalidatesTags: [{ type: "Articles"}],
        };
      },
      // onQueryStarted: ({ body }) => {
      //   console.log('Mutation query started. Body:', body);
      // },
    }),  
    deleteArticles: builder.mutation({
      query: ({ userId, articleId }) => ({
        url: `users/${userId}/articles/${articleId}`,
        method: "DELETE",
      }),
      // invalidatesTags: (result, error, arg) => [{ type: "Articles", id: arg.id }],
    }),
    getArticlesSearchQuery: builder.query({
      query: ({ limit, page, search }) => `/articles?limit=${limit}&page=${page}&search=${search}`,
      // invalidatesTags: ['Posts', 'PostSearchQuery'] as const,
    }),   
    likeArticle: builder.mutation({
      query: ({ userId, articleId }) => ({
          url: `users/${userId}/articles/${articleId}/like`,
          method: 'PATCH',
      }),
  }),
  dislikeArticle: builder.mutation({
      query: ({ userId, articleId }) => ({
          url: `users/${userId}/articles/${articleId}/dislike`,
          method: 'PATCH',
      }),
  }),
  commentOnArticle: builder.mutation({
    query: ({ userId, articleId, comment }) => ({
        url: `users/${userId}/articles/${articleId}/comment`,
        method: 'PATCH',
        body: comment,
    }),
}),
  bookmarkArticle: builder.mutation({
    query: ({ userId, articleId }) => ({
        url: `users/${userId}/bookmark-articles/${articleId}`,
        method: 'POST',
    }),
}),
  getBookmarks: builder.query({
    query: (userId) => ({
        url: `users/${userId}/bookmarks`,
    }),
  }),
  deleteBookmarkArticle: builder.mutation({
    query: ({ userId, articleId }) => ({
        url: `users/${userId}/bookmark-articles/${articleId}`,
        method: 'DELETE'
    }),
  }), 
  getBookmarkArticle: builder.query({
    query: (userId) => ({
        url: `users/${userId}/bookmark-articles`,
    }),
  }),   
  getUserArticle: builder.query({
    query: (userId) => ({
      url: `users/${userId}/articles`,
      invalidatesTags: [{ type: "Article", id: "LIST" }],
    })
  }),
  getArticleSearchQuery: builder.query({
    query: ({ limit, page, search }) => `/articles?limit=${limit}&page=${page}&search=${search}`,
  }),  
  }),
});

export const { 
  useGetArticlesQuery, 
  useLazyGetArticlesQuery,
  useCreateArticlesMutation,
   useGetArticleByIdQuery,
   useLazyGetArticleByIdQuery,
    useCommentOnArticleMutation, 
    useBookmarkArticleMutation, 
    useDeleteArticlesMutation,
  useDislikeArticleMutation,
useLikeArticleMutation,
useGetUserArticleQuery, 
useLazyGetUserArticleQuery,
useGetArticleSearchQueryQuery,
useDeleteBookmarkArticleMutation,
useGetBookmarkArticleQuery } = articleApiSlice;

const selectArticlesResult = articleApiSlice.endpoints.getArticles.select('');

const selectArticlesData = createSelector(
  selectArticlesResult,
  (articlesResult) => articlesResult.data
);

export const {
  selectAll: selectAllArticles,
  selectById: selectArticleById,
  selectIds: selectArticleIds,
} = articlesAdapter.getSelectors((state: RootState) =>
  selectArticlesData(state.api) ?? initialState
);