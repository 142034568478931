import React, { useEffect, useState } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  IconButton,
  useColorMode,
} from '@chakra-ui/react';
import {
  BiTrash,
  BiBookmark,
} from 'react-icons/bi';
import { CiMenuKebab } from 'react-icons/ci';
import { selectCurrentUser } from '../../slice/userSlice';
import { User } from '../../types/interface';
import { useSelector } from 'react-redux';
import ToastNotification from '../ToastNotification';
import { useBookmarkArticleMutation, useDeleteBookmarkArticleMutation, useDeleteArticlesMutation } from '../../api/articles';

const ArticleMenu: React.FC<{ articleId: any; ownerId: string }> = ({ articleId, ownerId }) => {
  const { colorMode } = useColorMode();
  const currentUser = useSelector(selectCurrentUser) as User;
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [deleteBookmark] = useDeleteBookmarkArticleMutation(); // Hook for deleting bookmark
  const [bookmarkAPost] = useBookmarkArticleMutation(); // Hook for bookmarking a post
  const [deletePost] = useDeleteArticlesMutation(); // Hook for deleting a post
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: "",
    status: "",
  });

  useEffect(() => {
    // Check if the current user has bookmarked the post
    const savedBookmarks = localStorage.getItem('bookmarkedArticles');
    if (savedBookmarks) {
      const parsedBookmarks = JSON.parse(savedBookmarks);
      setIsBookmarked(parsedBookmarks[articleId] || false);
    }
  }, [articleId]);

  const handleDeletePost = (articleId: any) => {
    deletePost({ userId: currentUser.id, articleId: articleId })
      .then((response: any) => {
        console.log("Deleted Post:", response);
        if (!response.error && response.data) {
          setShowToast(true); 
          setToastConfig({ description: "Article deleted successfully", status: "success" }); 
        } else {
          setShowToast(true);
          setToastConfig({ description: "Error deleting Article", status: "error" });
        }
      })
      .catch((error: any) => {
        console.error("Error deleting post:", error);
        setShowToast(true); 
        setToastConfig({ description: "Error deleting Article", status: "error" })
      });
  };
  
  const handleDeleteBookmark = (articleId: any) => {
    deleteBookmark({ userId: currentUser.id, articleId: articleId })
      .then((response: any) => {
        console.log("Deleted Bookmark:", response);
        if  ("data" in response) {
          setShowToast(true); // Set showToast to true when deleting bookmark succeeds
          setToastConfig({ description: "Bookmark deleted successfully", status: "success" }); // Set toast message and status
          updateBookmarkedStatus(articleId, false); 
        } else {
          setShowToast(true); // Set showToast to true even if there's an error (optional)
          setToastConfig({ description: "Error deleting bookmark", status: "error" }); // Set toast message and status for error
        }
      })
      .catch((error: any) => {
        console.error("Error deleting bookmark:", error);
        setShowToast(true); // Set showToast to true even if there's an error (optional)
        setToastConfig({ description: "Error deleting bookmark", status: "error" }); // Set toast message and status for error
      });
  };
  
  const handleBookmark = (articleId: any) => {
    bookmarkAPost({ userId: currentUser.id, articleId: articleId })
      .then((response: any) => {
        if  ("data" in response) {
          setShowToast(true); // Set showToast to true when bookmarking succeeds
          setToastConfig({ description: "Bookmark successful", status: "success" }); // Set toast message and status
          updateBookmarkedStatus(articleId, true);
        } else {
          setShowToast(true); // Set showToast to true even if there's an error (optional)
          setToastConfig({ description: "Error bookmarking post", status: "error" }); // Set toast message and status for error
        }
      })
      .catch((error: any) => {
        console.error("Error bookmarking post:", error);
        setShowToast(true); // Set showToast to true even if there's an error (optional)
        setToastConfig({ description: "Error bookmarking post", status: "error" }); // Set toast message and status for error
      });
  };

   const updateBookmarkedStatus = (articleId: any, isBookmarked: boolean) => {
    const savedBookmarks = localStorage.getItem('bookmarkedPosts');
    const updatedBookmarks = savedBookmarks ? JSON.parse(savedBookmarks) : {};
    updatedBookmarks[articleId] = isBookmarked;
    localStorage.setItem('bookmarkedPosts', JSON.stringify(updatedBookmarks));
    setIsBookmarked(isBookmarked);
  };
  

  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<CiMenuKebab fontSize={20} color={colorMode === 'light' ? '#345430' : 'white'} />}
        variant="ghost"
      />
      <MenuList bg={colorMode === 'light' ? '#345430' : 'white'}>
        {currentUser.id === ownerId && (
          <MenuItem bg={colorMode === 'light' ? '#345430' : 'white'} color={colorMode === 'light' ? '#fff' : '#345430'}>
            <Button
              aria-label="Options"
              flex={['1', '1', 'auto']}
              leftIcon={<BiTrash fontSize={20} color="#ff0000" />}
              variant="ghost"
              onClick={() => handleDeletePost(articleId)}
              color={colorMode === 'light' ? '#fff' : '#345430'}
            >
              Delete Articles
            </Button>
          </MenuItem>
        )}
        <MenuItem bg={colorMode === 'light' ? '#345430' : 'white'} color={colorMode === 'light' ? '#fff' : '#345430'}>
        {!isBookmarked ? (
          <Button
            aria-label="Bookmark"
            flex={['1', '1', 'auto']}
            leftIcon={<BiBookmark fontSize={20} color={colorMode === 'light' ? '#fff' : '#345430'} />}
            variant="ghost"
            onClick={() => handleBookmark(articleId)}
            color={colorMode === 'light' ? '#fff' : '#345430'}
          >
            Bookmark Article
          </Button>
        ) : (
          <Button
            aria-label="Remove Bookmark"
            flex={['1', '1', 'auto']}
            leftIcon={<BiTrash fontSize={20} color="#ff0000" />}
            variant="ghost"
            onClick={() => handleDeleteBookmark(articleId)}
            color={colorMode === 'light' ? '#fff' : '#345430'}
          >
            Remove Bookmark
          </Button>
        )}
    </MenuItem>

      </MenuList>
      {showToast && (
              <ToastNotification
                description={toastConfig.description}
                status={toastConfig.status}
                onClose={() => setShowToast(false)}
              />
            )}
    </Menu>
  );
};

export default ArticleMenu;
