import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Layout } from './components';
import { 
  About,
  Bookmarks,
  Contact, 
  CreatePost, 
  Donate, 
  ErrorPage, 
  ForgotPassword,
  Home, 
  Login, 
  PostById, 
  Posts, 
  Project, 
  ResetPassword, 
  SearchPage, 
  Shop, 
  SignUp, 
  UserProfile, 
  UserProfileSettings, 
  ArticleById,
  Article,
  CreateArticle,
  EditPost,
} from './pages';
import { useEffect } from 'react';


function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
      <Router basename='/'> 
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route  path="about" element={<About />} />
            <Route  path="donate" element={<Donate />} />
            <Route  path="contact" element={<Contact />} />
            <Route  path="project" element={<Project title={''} content={''} image={''} />} />
            <Route  path="profile" element={<UserProfile posts={[]} />} />
            <Route path='login' element={<Login/>} />
            <Route path='signup' element={<SignUp />} />
            <Route path='forgot-password' element={<ForgotPassword />} />
            <Route path="/resetpassword/:tokenId/:userId" 
            element={<ResetPassword />} />
            <Route path='shop' element={<Shop />} />
            <Route path='search' element={<SearchPage />} />

            <Route path="user">
            <Route path=":userId">
          <Route path="" index element={<UserProfile posts={[]} />} />
          <Route path="settings" element={<UserProfileSettings />} />
          <Route path="bookmarks" element={<Bookmarks />} />
          <Route path='posts' element={<Posts /> }/>
          <Route path='articles' element={<Article />}/>
          <Route path="articles/:articleId" element={<ArticleById  />} />
          
          {/* Nested routes starting from /posts/:postId */}
          <Route path="posts">
         <Route path=":postId" element={<PostById />} />   
          </Route>
          <Route path="edit-post/:postId" element={<EditPost />} />
          <Route path="create-post" element={<CreatePost />} />

          <Route path="create-article" element={<CreateArticle />} />
        </Route>

            </Route>

            <Route path="*" element={<ErrorPage />} />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
  );
}

export default App;