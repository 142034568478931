import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { useParams, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Box,
  Text,
  VStack,
  Button,
  Flex,
  useColorMode,
  Divider,
  Skeleton,
  Icon,
} from '@chakra-ui/react';
import { FaCog } from 'react-icons/fa';
import { IoBookmarks } from 'react-icons/io5';
import { Helmet } from 'react-helmet';
import { selectCurrentUser } from '../slice/userSlice';
import {
  User,
  Articles,
  Post,
  PostCardProps,
  RootState,
} from '../types/interface';
import {
  useFollowUsersMutation,
  useLazyGetUserPostQuery,
  useLazyGetUserProfileQuery,
} from '../api/userApi';
import {
  useLazyGetUserArticleQuery,
} from '../api/articles';
import { AutoBackButton, ToastNotification, UserArticle, UserPosts } from '../components';
import { GoVerified } from 'react-icons/go';
import { BiError } from 'react-icons/bi';

const UserProfile: React.FC<PostCardProps> = () => {
  const { userId } = useParams();
  const currentUser = useSelector(selectCurrentUser) as User;
  const isAuthenticated = useSelector(
    (state: RootState) => state.users.isLogin
  );
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
  const [triggerUserProfile, { data: userData, isLoading: userLoading, isError: userError }] = useLazyGetUserProfileQuery();
  const [triggerUserPosts, { data: posts, isLoading: postsLoading }]  = useLazyGetUserPostQuery();
  const [triggerUserArticles, { data: article, isLoading: articleLoading }] = useLazyGetUserArticleQuery();
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: '',
    status: '',
  });


  useEffect(() => {
    if (userId) {
      triggerUserProfile(userId, true); // Fetch user profile data, prefer cache if available
      triggerUserPosts(userId, true); // Fetch user post data, prefer cache if available'
      triggerUserArticles(userId, true)
    }
  }, [userId, triggerUserProfile, triggerUserPosts, triggerUserArticles]);

  // console.log("user Id: ", userId)
  const [allPosts, setAllPosts] = useState<Post[]>(posts?.data?.posts || []);
  const [articles, setArticles] = useState<Articles[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [selectedSegment, setSelectedSegment] = useState('Posts');

  const handleSegmentChange = (segment: string) => {
    setSelectedSegment(segment);
  };

  const updatePostData = (postId: string, newData: any) => {
    setAllPosts((prevPosts: any[]) =>
      prevPosts.map((post: any) =>
        post._id === postId ? { ...post, ...newData } : post
      )
    );
  };

  const updatedArticleData = (articleId: string, newData: Partial<Articles>) => {
    setArticles((prevPost) => ({
      ...prevPost,
      ...newData,
    }));
  };

  const [followUsersMutation] = useFollowUsersMutation();

  const handleFollowUser = async () => {
    if (userId) {
      try {
        const response = await followUsersMutation({ userId });

        if ('data' in response && response.data?.statusCode === 201) {
          setToastConfig({
            description: response.data.data.message,
            status: 'success',
          });
          setShowToast(true);
          console.log('User followed successfully:', response);
          window.location.reload();
        } else if ('error' in response) {
          const errorMessage = 'An error occurred while following the user.';

          setToastConfig({
            description: errorMessage,
            status: 'error',
          });
          setShowToast(true);
        }
      } catch (error) {
        setToastConfig({
          description: 'An error occurred while following the user.',
          status: 'error',
        });
        setShowToast(true);
      }
    } else {
      console.error('User ID is undefined');
    }
  };

  useEffect(() => {
    if (posts) {
      const post = posts.data.posts;
      setAllPosts(post);
    }
  }, [posts]);

  useEffect(() => {
    if (article) {
      const allArticles = article.data.articles;
      setArticles(allArticles);
    }
  }, [article]);

  // console.log("User's Articles: ", articles) 
  // console.log("User's Post: ", allPosts)

  // const locationName = useSelector(
  //   (state: RootState) => state.reverseGeocode.locationName
  // );

  const handlePostCardClick = (postId: string) => {
    if (isAuthenticated) {
      navigate(`/user/${userId}/posts/${postId}`);
    } else {
      navigate('/login');
    }
  };

  const handleArticleClick = (articleId: string) => {
    navigate(`/user/${userId}/articles/${articleId}`);
  };
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      style={{ backgroundColor: colorMode === 'light' ? 'white' : '#345430' }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>User Profile Page</title>
      </Helmet>
      <Box
        paddingTop="20px"
        paddingBottom="20px"
        marginRight={{ base: '15', md: '40' }}
        marginLeft={{ base: '15', md: '40' }}
        fontFamily="Inter"
        justifyContent="center"
        alignItems="center"
        color={colorMode === 'light' ? '#345430' : 'white'}
      >
       <AutoBackButton />
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, ease: 'easeIn' }}>
          <Box p={10}>
            {userError ? (
              <div>Failed to Get User Profile</div>
            ) : (
              <div>
                {userLoading && (
                  <Box flex="1">
                    <Skeleton height="200px" borderRadius={20} color={'transparent'} width="100%" />
                  </Box>
                )}
                {!userLoading && (
                  <Flex
                    direction={{ base: 'column', md: 'row' }}
                    align={{ base: 'start', md: 'center' }}
                    justify="space-between"
                    gap={10}
                    fontFamily="Inter"
                  >
                    <VStack align="start" spacing={4}>
                      <Text fontSize="xl" fontWeight="bold">
                        {userData?.data?.user?.name}
                      </Text>
                      {userData?.data?.user?.username && (
                        <Flex align="center">
                          <Text fontSize={16}>@{userData?.data?.user?.username}</Text>
                          <Icon borderRadius={'10px'} ml={2}>
                            {userData?.data?.user?.verify ? (
                              <GoVerified
                                style={{
                                  color: colorMode === 'light' ? '#345430' : 'white',
                                  backgroundColor: colorMode === 'light' ? 'white' : '#345430'
                                }}
                                fontSize={25}
                              />
                            ) : (
                              <BiError
                                style={{
                                  color: 'red',
                                  backgroundColor: 'red'
                                }}
                                fontSize={25}
                              />
                            )}
                          </Icon>
                        </Flex>
                      )}
                      {userData?.data?.user?.field && (
                        <Text style={{ textTransform: 'capitalize' }} fontSize={16}>
                          {userData?.data?.user?.field}
                        </Text>
                      )}
                      {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <FaMapMarkerAlt color={colorMode === 'light' ? '#345430' : 'white'} size={20} />
                        <Text color={colorMode === 'light' ? '#000' : 'white'} textAlign={'start'} p={2} fontSize={{ base: '16px', md: '20px', lg: '20px' }}>
                          {locationName}
                        </Text>
                      </div> */}
                      {userData?.data?.profile?.about && (
                        <Text style={{ textTransform: 'capitalize', textAlign: 'justify', fontSize: '16px' }}>
                          {userData?.data?.profile?.about}
                        </Text>
                      )}
                    </VStack>
                    <VStack display={'flex'} flexDirection={'column'}>
                      {currentUser.id === userId && (
                        <VStack display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                          <VStack alignItems="start" spacing={4}>
                            <Button
                              onClick={() => {
                                navigate(`/user/${userId}/settings`);
                              }}
                              disabled={currentUser.id !== userId}
                              cursor={'pointer'}
                            >
                              <FaCog color={colorMode === 'light' ? '#345430' : 'white'} size={20} />
                            </Button>
                          </VStack>
                          <VStack alignItems="start" spacing={4}>
                            <Button
                              onClick={() => {
                                navigate(`/user/${userId}/bookmarks`);
                              }}
                              disabled={currentUser.id !== userId}
                              cursor={'pointer'}
                            >
                              <IoBookmarks color={colorMode === 'light' ? '#345430' : 'white'} size={20} />
                            </Button>
                          </VStack>
                        </VStack>
                      )}
                      <VStack display={'flex'} flexDirection={'row'}>
                        <VStack alignItems="start" spacing={4}>
                          {userId !== currentUser.id && (
                            <Button onClick={() => handleFollowUser()} disabled={userId === currentUser.id}>
                              Follow User
                            </Button>
                          )}
                        </VStack>
                        <VStack alignItems="start" spacing={4}>
                          <Button>
                            Following {userData?.data?.user?.followers.length || 0}
                          </Button>
                        </VStack>
                        <VStack alignItems="start" spacing={4}>
                          <Button>
                            Followers {userData?.data?.user?.follow.length || 0}
                          </Button>
                        </VStack>
                      </VStack>
                    </VStack>
                  </Flex>
                )}
              </div>
            )}
          </Box>
        </motion.div>
      </Box>
      <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, ease: 'easeIn' }}>
        <Box
          marginRight={{ base: '15', md: '40' }}
          marginLeft={{ base: '15', md: '40' }}
          fontFamily="Inter"
          justifyContent="center"
          alignItems="center"
        >
          <VStack
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={10}
            p={10}
          >
            <Text
              onClick={() => handleSegmentChange('Posts')}
              colorScheme={selectedSegment === 'Posts' ? 'green' : 'gray'}
              color={colorMode === 'light' ? '#345430' : '#fff'}
              size="lg"
              width={'md'}
              position="relative"
            >
              Posts
              {selectedSegment === 'Posts' && (
                <Box
                  position="absolute"
                  bottom="-10px"
                  left="5%"
                  transform="translateX(-50%)"
                  h="5px"
                  w="5px"
                  bg={colorMode === 'light' ? '#345430' : '#fff'}
                  borderRadius={'50%'}
                />
              )}
            </Text>
            <Text
              onClick={() => handleSegmentChange('Articles')}
              colorScheme={selectedSegment === 'Articles' ? 'green' : 'gray'}
              color={colorMode === 'light' ? '#345430' : '#fff'}
              size="lg"
              width={'md'}
              position="relative"
            >
              Articles
              {selectedSegment === 'Articles' && (
                <Box
                  position="absolute"
                  bottom="-10px"
                  left="8%"
                  transform="translateX(-50%)"
                  h="5px"
                  w="5px"
                  bg={colorMode === 'light' ? '#345430' : '#fff'}
                  borderRadius={'50%'}
                />
              )}
            </Text>
          </VStack>
          <Divider my={8} />
          <Swiper grabCursor={true} centeredSlides={true} pagination={{ clickable: true }}>
            <SwiperSlide>
              {selectedSegment === 'Posts' ? (
                <UserPosts
                  posts={allPosts}
                  handlePostCardClick={handlePostCardClick}
                  isLoading={postsLoading}
                  updatedPostData={updatePostData}
                />
              ) : (
                <UserArticle
                  articles={articles}
                  handleArticleClick={handleArticleClick}
                  isLoading={articleLoading}
                  updatedArticleData={updatedArticleData}
                  userId={userId}
                />
              )}
            </SwiperSlide>
          </Swiper>
          {showToast && (
            <ToastNotification
              description={toastConfig.description}
              status={toastConfig.status}
              onClose={() => setShowToast(false)}
            />
          )}
        </Box>
      </motion.div>
    </motion.div>
  );
};

export default UserProfile;
