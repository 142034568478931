import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  SimpleGrid,
  Skeleton,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetAllPostsQuery } from "../../api/postApi";
import HomePostContent from "./HomePostContent";
import { Post, PostCardProps, User } from "../../types/interface";
import { selectCurrentUser } from "../../slice/userSlice";
import { useSelector } from "react-redux";

const itemsPerPage = 6;

const HomeCollections: React.FC<PostCardProps> = () => {
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const { userId } = useParams();
  const currentUser = useSelector(selectCurrentUser) as unknown as User
  const validUserId = userId || currentUser?.id;
  const [trigger, { data: allPostsData, isLoading: allPostsLoading, isError: allPostsError }] = useLazyGetAllPostsQuery();

  const [currentPage, setCurrentPage] = useState(1);
  const [allPosts, setAllPosts] = useState<Post[]>([]);

  useEffect(() => {
    trigger({}, true); // Prefer cached value if available
  }, [trigger]);

  useEffect(() => {
    if (allPostsData) {
      const posts = allPostsData?.data?.posts
      setAllPosts(posts);
    }
  }, [allPostsData]);

  // console.log('all post: ', allPostsData);

  const totalPages = Math.ceil(Array.isArray(allPosts) ? allPosts.length / itemsPerPage : 0);
  const indexOfLastPost = Math.min(currentPage * itemsPerPage, allPosts.length);
  const indexOfFirstPost = Math.max(indexOfLastPost - itemsPerPage, 0);
  

  const updatePostData = (postId: string, newData: any) => {
    setAllPosts((prevPosts: any[]) =>
      prevPosts.map((post: any) =>
        post._id === postId ? { ...post, ...newData } : post
      )
    );
  };
  
  const handlePostCardClick = (postId: string) => {
    navigate(`/user/${validUserId}/posts/${postId}`);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeIn" }}
      style={{
        backgroundColor: colorMode === "light" ? "white" : "#345430",
        color: colorMode === "light" ? "white" : "#345430",
      }}
    >
      <Text
        fontSize={{ base: "20px", md: "30px", lg: "40px" }}
        fontWeight="600"
        fontFamily="Inter"
        letterSpacing={3}
        textAlign="center"
        color={colorMode === "light" ? "#345430" : "white"}
        paddingTop="40px"
        paddingBottom="20px"
      >
        Posts
      </Text>
      <Box
        style={{
          backgroundColor: colorMode === "light" ? "#E0EAE0" : "#588552",
          borderRadius: "20px",
        }}
      >
        {allPostsError ? (
          <Box textAlign="center" paddingTop="20px" fontSize="18px" fontWeight="bold">
            Error loading posts. Please try again.
          </Box>
        ) : (
      <Box paddingTop="20px" pb={20} color={colorMode === "light" ? "#345430" : "white"}>
            {allPostsLoading ? ( // Render loading indicator if data is loading
              <Box textAlign="center" p={10}>
                  <Skeleton height="500px" borderRadius={20} color={"transparent"} />
              </Box>
            ) : (
                            <>
             <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacingX="40px" spacingY="20px" p={10}>
                {Array.isArray(allPosts) && allPosts.slice(indexOfFirstPost, indexOfLastPost).map((post) => (
                  post && (
                    <HomePostContent
                      key={post._id}
                      post={post}
                      handlePostCardClick={handlePostCardClick}
                      isLoading={allPostsLoading}
                      updatedPostData={updatePostData} 
                    />
                  )
                ))}
              </SimpleGrid>
                <Box textAlign="center" p={10} mt="4">
                  {Array.from({ length: totalPages }).map((_, index) => (
                    <Button
                      key={index}
                      mx="1"
                      variant="ghost"
                      onClick={() => handlePageChange(index + 1)}
                      colorScheme={currentPage === index + 1 ? "green" : undefined}
                      backgroundColor={currentPage === index + 1 ? "white" : undefined}
                      marginBottom={'20px'}
                    >
                      {index + 1}
                    </Button>
                  ))}
                </Box>
              </>
            )}
        </Box>
        )}
      </Box>
    </motion.div>
  );
};

export default HomeCollections;
