import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from "react-router-dom";
import { motion } from 'framer-motion';
import { Box, Heading, Text, Flex, Tag, Skeleton, Alert, AlertIcon, Image, useColorMode, Icon } from '@chakra-ui/react';
import { Articles, RootState } from '../types/interface';
import { useSelector } from 'react-redux';
import { ArticleMenu, ArticlesButtons, AutoBackButton, formatCreatedAtDate } from '../components';
import { useLazyGetArticleByIdQuery } from "../api/articles";
import { Link } from 'react-router-dom';
import ArticleComment from '../components/Articles/ArticleComments';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';


const ArticleById = () => {
  const { articleId } = useParams<{ articleId?: string }>();
  const [trigger, { data, isLoading, isError }]  =  useLazyGetArticleByIdQuery();
  const [article, setArticle] = useState<Articles>(data?.data || {});
  const isAuthenticated = useSelector((state: RootState) => state.users.isLogin);
  const { colorMode } = useColorMode();


  useEffect(() => {
    if (articleId) {
      trigger(articleId, true); // Fetch user profile data, prefer cache if available
    }
  }, [articleId, trigger]);

  useEffect(() => {
    if (data) {
      const articles = data?.data;
      setArticle(articles);
    }
  }, [data]);

  // console.log("Articles: ", article)
  const updateArticleData = (newData: Partial<Articles>) => {
    setArticle(prevPost => ({
      ...prevPost,
      ...newData
    }));
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>
         {article.title}
        </title>
      </Helmet>

      <Box
        paddingTop="20px"
        paddingBottom="20px"
        marginRight={{ base: '15', md: '20' }}
        marginLeft={{ base: '15', md: '20' }}
        fontFamily="Inter"
      >
        {isLoading ? (
          <Skeleton height="20px" mb="10px" />
        ) : isError ? (
          <Alert status="error">
            <AlertIcon />
            Error loading article. Please try again later.
          </Alert>
        ) : (
          <>
            <Flex justify="space-between" alignItems="center">
            <AutoBackButton/>
              <Box display={'block'} alignItems={'start'}>
              <Flex alignItems="center">
                  <Heading as="h2" size="xl" mb={4}>
                    {article.title}
                  </Heading>
                  <Icon
                    as={article.verify ? FaCheckCircle : FaTimesCircle}
                    color={article.verify ? 'green.500' : 'red.500'}
                    fontSize={20}
                    cursor="pointer"
                    ml={2}
                  />
                </Flex>
                <Text
                  textAlign="start"
                  paddingBottom="5px"
                  fontFamily="Inter"
                  paddingTop="5px"
                  fontWeight={600}
                  fontSize={16}
                >
                  {isAuthenticated ?  
                    <Link to={`/user/${article?.user?._id}`}>@{article?.user?.username}</Link>
                    : <Link to={'/login'}/>}
                </Text>
                <Text
                  paddingBottom="5px"
                  textAlign="start"
                  fontFamily="Inter"
                  fontWeight={600}
                  fontSize={16}
                >{formatCreatedAtDate(article?.createdAt)}</Text>
              </Box>
              {isAuthenticated && article && article.user ? 
                <ArticleMenu articleId={article._id} ownerId={article.user._id} />
              : <Link to={'/login'}/>}
            </Flex>
            {article?.images && article?.images?.length > 0 && (
                  <Box mt={2} display="flex" flexWrap="wrap">
                    {article?.images.map((image: string, imageIndex: number) => (
                      <Image
                        key={imageIndex}
                        src={image}
                        alt={article?.title}
                        borderRadius={"10px"}
                        style={{ 
                          marginRight: '8px', 
                          marginBottom: '8px',
                          maxWidth: '100%',
                          flex: '1 0 300px', // Adjust the width as needed
                        }}
                        width={"100%"}
                        height={"100%"}
                        objectFit="cover"
                      />
                    ))}
                  </Box>
                )}
          <Text fontSize="lg" letterSpacing="2px" color={colorMode === 'light' ? '#345430' : 'white'} lineHeight="2" mt={4}>
                  {/* Wrap the HTML content in a Box and set the color */}
                  <Box
                    as="div"
                    className={colorMode === 'light' ? 'article-content-light preview-content' : 'article-content-dark preview-content'}
                    dangerouslySetInnerHTML={{ __html: article?.htmlContent }}
                    style={{ fontFamily: "Inter", fontSize: "16px" }}
                  />

          </Text>
            {/* Tags */}
            <Flex mt={4}>
              {article?.keywords?.map((keyword, index) => (
                <Tag key={index} mr={2}>
                  {keyword}
                </Tag>
              ))}
            </Flex>
            <ArticlesButtons
              article={article} 
              updatedArticleData={updateArticleData}
            />
              <ArticleComment   article={article}  updatedArticleData={updateArticleData} />
          </>
        )}
      </Box>
    </motion.div>
  );
};

export default ArticleById;
