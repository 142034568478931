import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  useColorMode,
  IconButton,
  Textarea,
  Spinner,
} from '@chakra-ui/react';
import { FaBold, FaItalic, FaUnderline, FaFilePdf, FaImage } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import axios from 'axios';
import ToastNotification from '../ToastNotification'; // Assuming this is your custom ToastNotification component
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { useCreateArticlesMutation } from '../../api/articles';
import { User } from '../../types/interface';
import { selectAccessToken, selectCurrentUser } from '../../slice/userSlice';

export interface FormValues extends FieldValues {
  title: string;
  keywords: string;
  content: string;
  pdfFiles: FileList;
}

const ArticleForm: React.FC = () => {
  const { colorMode } = useColorMode();
  const { register, handleSubmit, reset, setValue } = useForm<FormValues>();
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({ description: '', status: '' });
  const fileInputRef = useRef<HTMLInputElement>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);
  const [uploadArticles] = useCreateArticlesMutation();
  const currentUser = useSelector(selectCurrentUser) as User;
  const navigate = useNavigate();
  const accessToken = useSelector(selectAccessToken);
  const [loading, setLoading] = useState(false);

  const [previewContent, setPreviewContent] = useState<string>('');

  const handleContentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setValue('content', newValue);
    setPreviewContent(newValue); // Update preview content
  };

  const formatText = (command: string) => {
    const textarea = document.querySelector('textarea') as HTMLTextAreaElement;
    const { selectionStart, selectionEnd, value } = textarea;

    const before = value.substring(0, selectionStart);
    const selectedText = value.substring(selectionStart, selectionEnd);
    const after = value.substring(selectionEnd);

    let newValue = '';

    switch (command) {
      case 'bold':
        newValue = `${before}<b>${selectedText}</b>${after}`;
        break;
      case 'italic':
        newValue = `${before}<i>${selectedText}</i>${after}`;
        break;
      case 'underline':
        newValue = `${before}<u>${selectedText}</u>${after}`;
        break;
      case 'heading1':
        newValue = `${before}<h1>${selectedText}</h1>${after}`;
        break;
      case 'heading2':
        newValue = `${before}<h2>${selectedText}</h2>${after}`;
        break;
      case 'heading3':
        newValue = `${before}<h3>${selectedText}</h3>${after}`;
        break;
      case 'heading4':
        newValue = `${before}<h4>${selectedText}</h4>${after}`;
        break;
      case 'heading5':
        newValue = `${before}<h5>${selectedText}</h5>${after}`;
        break;
      case 'heading6':
        newValue = `${before}<h6>${selectedText}</h6>${after}`;
        break;
      default:
        newValue = value;
        break;
    }

    textarea.value = newValue;
    setValue('content', newValue); // Update form state with new content
    setPreviewContent(newValue); // Update preview content
  };

  const uploadImage = async (file: File) => {
    setLoading(true);

    setToastConfig({
      description: 'Uploading image...',
      status: 'info'
    });
    setShowToast(true);
    try {
      const formData = new FormData();
      formData.append('files', file);

      const response = await axios.post(
        `https://ewe-api.herokuapp.com/api/users/${currentUser.id}/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin': '*/*',
          },
        }
      );

      if ('data' in response && response?.data?.data) {
        const imageUrl = response.data.data;

        setToastConfig({ 
          description: 'Success !!! Image was successfully uploaded!',
          status: 'success'
        });
        setShowToast(true);

        setLoading(false);

        return imageUrl; // Return the uploaded image URL
      } else {
        setToastConfig({ 
          description: 'Invalid response from file upload mutation',
          status: 'error'
        });
        setShowToast(true);
      }
    } catch (error) {
      console.error('Error uploading file:', error);

      setToastConfig({ 
        description: 'Error uploading file. Please try again.',
        status: 'error'
      });
      setShowToast(true);

      setLoading(false);
      return null; // Handle error by returning null or appropriate value
    }
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const filesArray = Array.from(files); // Convert FileList to Array
      const imageUrls: string[] = [];
      setLoading(true); // Show loader during upload

      for (const file of filesArray) {
        const imageUrl = await uploadImage(file);
        if (imageUrl) {
          imageUrls.push(imageUrl);
        }
      }

      if (imageUrls.length > 0) {
        const textarea = document.querySelector('textarea') as HTMLTextAreaElement;
        const currentValue = textarea.value;
        const newValue = imageUrls.reduce(
          (acc, url) => `${acc}\n<img src="${url}" alt="uploaded image" />`,
          currentValue
        );

        textarea.value = newValue;
        setValue('content', newValue); // Update form state with new content
      }

      setLoading(false); // Hide loader after upload
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    // const pdfFilesArray = Array.from(data.pdfFiles);
    const htmlContent = data.content; // Get content from form state

    try {
      const response = await uploadArticles({
        userId: currentUser.id,
        body: {
          pdf: data.pdfFiles,
          htmlContent: htmlContent,
          keywords: data.keywords,
          title: data.title,
        },
      });

      if ('data' in response) {
        const responseData = response.data;
        const message = responseData.message || 'Success !!! Article was successfully sent!';
        setToastConfig({ description: message, status: 'success' });
        setShowToast(true);
        reset();
        navigate(`/user/${currentUser.id}/articles`);
      } else if ('error' in response) {
        const errorResponseData = response as { error?: string; message?: string };
        const errorMessage = errorResponseData.message || 'Error in Creating Article';
        setToastConfig({ description: errorMessage, status: 'error' });
        setShowToast(true);
      }
    } catch (error: any) {
      console.error('An error occurred:', error);
      setToastConfig({ description: error.message || 'An error occurred', status: 'error' });
      setShowToast(true);
    }
  };

  return (
    <Box margin="auto" maxW={{ base: '95%', md: '80%', lg: '70%' }} p={4}>
      <Box
        backgroundColor={colorMode === 'light' ? '#fff' : '#E0EAE0'}
        color={colorMode === 'light' ? '#000' : '#345430'}
        fontFamily="Inter"
        borderRadius="10px"
        mt={10}
        p={{ base: 4, md: 6 }}
        fontSize={{ base: '16px', md: '20px' }}
      >
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" method="POST">
          <FormControl mt={4} mb={4}>
            <FormLabel fontFamily="Inter" fontSize={{ base: '16px', md: '18px' }} fontWeight="bold">
              Title
            </FormLabel>
            <Input
              type="text"
              placeholder="Enter article title"
              backgroundColor="#E0EAE0"
              borderColor="#345430"
              fontFamily="Inter"
              fontSize={{ base: '16px', md: '20px' }}
              _focus={{ borderColor: '#345430' }}
              width="100%"
              height="60px"
              p={5}
              {...register('title', { required: true })}
            />
          </FormControl>
          <FormControl mt={4} mb={4}>
            <FormLabel fontFamily="Inter" fontSize={{ base: '16px', md: '18px' }} fontWeight="bold">
              Keywords (Separate by comma)
            </FormLabel>
            <Input
              type="text"
              placeholder="Enter keywords"
              backgroundColor="#E0EAE0"
              borderColor="#345430"
              fontFamily="Inter"
              fontSize={{ base: '16px', md: '20px' }}
              _focus={{ borderColor: '#345430' }}
              width="100%"
              height="60px"
              p={5}
              {...register('keywords', { required: true })}
            />
          </FormControl>
          <FormControl mt={4} mb={4}>
            <FormLabel fontFamily="Inter" fontSize={{ base: '16px', md: '18px' }} fontWeight="bold">
              Upload PDF Files
            </FormLabel>
            <Input
              type="file"
              accept=".pdf"
              // multiple
              ref={fileInputRef}
              display="none"
              // {...register('pdfFiles')}
            />
            <Button
              onClick={() => fileInputRef.current?.click()}
              backgroundColor="#345430"
              color="#fff"
              fontFamily="Inter"
              fontSize={{ base: '16px', md: '20px' }}
              width="100%"
              height="60px"
              p={5}
            >
              <FaFilePdf />
              &nbsp; Upload PDF Files
            </Button>
          </FormControl>
          <FormControl mt={4} mb={4}>
            <FormLabel fontFamily="Inter" fontSize={{ base: '16px', md: '18px' }} fontWeight="bold">
              Content
            </FormLabel>
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="space-between"
              mb={4}
            >
              <IconButton
                icon={<FaBold />}
                aria-label="Bold"
                onClick={() => formatText('bold')}
                backgroundColor={colorMode === 'light' ? '#fff' : '#E0EAE0'}
                color={colorMode === 'light' ? '#000' : '#345430'}
                fontFamily="Inter"
                fontSize={{ base: '14px', md: '16px' }}
                m={1}
              />
              <IconButton
                icon={<FaItalic />}
                aria-label="Italic"
                onClick={() => formatText('italic')}
                backgroundColor={colorMode === 'light' ? '#fff' : '#E0EAE0'}
                color={colorMode === 'light' ? '#000' : '#345430'}
                fontFamily="Inter"
                fontSize={{ base: '14px', md: '16px' }}
                m={1}
              />
              <IconButton
                icon={<FaUnderline />}
                aria-label="Underline"
                onClick={() => formatText('underline')}
                backgroundColor={colorMode === 'light' ? '#fff' : '#E0EAE0'}
                color={colorMode === 'light' ? '#000' : '#345430'}
                fontFamily="Inter"
                fontSize={{ base: '14px', md: '16px' }}
                m={1}
              />
        <IconButton
                icon={<Box as="span">H1</Box>}
                aria-label="Heading1"
                onClick={() => formatText('heading1')}
                backgroundColor={colorMode === 'light' ? '#fff' : '#E0EAE0'}
                color={colorMode === 'light' ? '#000' : '#345430'}
                fontFamily="Inter"
                fontSize={{ base: '14px', md: '16px' }}
                m={1}
              />
                   <IconButton
                icon={<Box as="span">H2</Box>}
                aria-label="Heading2"
                onClick={() => formatText('heading2')}
                backgroundColor={colorMode === 'light' ? '#fff' : '#E0EAE0'}
                color={colorMode === 'light' ? '#000' : '#345430'}
                fontFamily="Inter"
                fontSize={{ base: '14px', md: '16px' }}
                m={1}
              />
              <IconButton
                icon={<Box as="span">H3</Box>}
                aria-label="Heading3"
                onClick={() => formatText('heading3')}
                backgroundColor={colorMode === 'light' ? '#fff' : '#E0EAE0'}
                color={colorMode === 'light' ? '#000' : '#345430'}
                fontFamily="Inter"
                fontSize={{ base: '14px', md: '16px' }}
                m={1}
              />
              <IconButton
                icon={<Box as="span">H4</Box>}
                aria-label="Heading4"
                onClick={() => formatText('heading4')}
                backgroundColor={colorMode === 'light' ? '#fff' : '#E0EAE0'}
                color={colorMode === 'light' ? '#000' : '#345430'}
                fontFamily="Inter"
                fontSize={{ base: '14px', md: '16px' }}
                m={1}
              />
              <IconButton
                icon={<Box as="span">H5</Box>}
                aria-label="Heading5"
                onClick={() => formatText('heading5')}
                backgroundColor={colorMode === 'light' ? '#fff' : '#E0EAE0'}
                color={colorMode === 'light' ? '#000' : '#345430'}
                fontFamily="Inter"
                fontSize={{ base: '14px', md: '16px' }}
                m={1}
              />
              <IconButton
                icon={<Box as="span">H6</Box>}
                aria-label="Heading6"
                onClick={() => formatText('heading6')}
                backgroundColor={colorMode === 'light' ? '#fff' : '#E0EAE0'}
                color={colorMode === 'light' ? '#000' : '#345430'}
                fontFamily="Inter"
                fontSize={{ base: '14px', md: '16px' }}
                m={1}
              />
              <IconButton
                icon={<FaImage />}
                aria-label="Upload Image"
                onClick={() => imageInputRef.current?.click()}
                backgroundColor={colorMode === 'light' ? '#fff' : '#E0EAE0'}
                color={colorMode === 'light' ? '#000' : '#345430'}
                fontFamily="Inter"
                fontSize={{ base: '14px', md: '16px' }}
                m={1}
              />
            </Box>
            <Box mt={4} border="1px solid #ddd" p={4} borderRadius="md" mb={5} whiteSpace="pre-wrap">
                <div
                  className="preview-content"
                  dangerouslySetInnerHTML={{ __html: previewContent }}
                  style={{ 
                    whiteSpace: 'pre-wrap'
                  }}
                />
              </Box>
            <Textarea
              placeholder="Enter article content"
              backgroundColor="#E0EAE0"
              borderColor="#345430"
              fontFamily="Inter"
              fontSize={{ base: '16px', md: '20px' }}
              height={'500px'}
              _focus={{ borderColor: '#345430' }}
              rows={10}
              {...register('content')}
              onChange={handleContentChange}
            />
          </FormControl>
          <Input
            type="file"
            accept="image/*"
            multiple
            ref={imageInputRef}
            display="none"
            onChange={handleImageUpload}
          />
          <Button
            type="submit"
            backgroundColor="#345430"
            color="#fff"
            fontFamily="Inter"
            fontSize={{ base: '16px', md: '20px' }}
            width="100%"
            height="60px"
            p={5}
            isDisabled={loading}
          >
            {loading ? <Spinner /> : 'Submit Article'}
          </Button>
        </form>
      </Box>
      {showToast && (
        <ToastNotification
          description={toastConfig.description}
          status={toastConfig.status}
          onClose={() => setShowToast(false)}
        />
      )}
    </Box>
  );
};

export default ArticleForm;
