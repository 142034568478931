import React, { useState, useEffect, useCallback } from "react";
import { Button, Menu, MenuButton, MenuList, MenuItem, Box } from "@chakra-ui/react";
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../../slice/languageSlice';
import { Post, RootState } from "../../types/interface";
import { useLazyTranslateTextQuery } from "../../api/translation";
import { FaChevronDown } from "react-icons/fa";

interface TranslationToggleProps {
  postId: string;
  description: string;
  uses: string;
  setTranslatedPost: (post: Partial<Post> | null) => void;
  postLang: string; // Add postLang prop
}

const languages = [
  { code: 'en', label: 'English' },
  { code: 'yo', label: 'Yoruba' },
  { code: 'es', label: 'Spanish' },
  // Add more languages as needed
];

const TranslationToggle: React.FC<TranslationToggleProps> = ({ description, uses, setTranslatedPost, postLang }) => {
  const [showTranslation, setShowTranslation] = useState(false);
  const language = useSelector((state: RootState) => state.language.language);
  const dispatch = useDispatch();

  const [triggerDescriptionTranslation, { data: descriptionData, error: descriptionError }] = useLazyTranslateTextQuery();
  const [triggerUsesTranslation, { data: usesData, error: usesError }] = useLazyTranslateTextQuery();

  const updateTranslation = useCallback(() => {
    if (descriptionData?.data && descriptionData.data.translations.length > 0 &&
        usesData?.data && usesData.data.translations.length > 0) {
      setTranslatedPost({
        description: descriptionData.data.translations[0].translatedText,
        uses: usesData.data.translations[0].translatedText
      });
    }
  }, [descriptionData, usesData, setTranslatedPost]);

  useEffect(() => {
    if (showTranslation) {
      triggerDescriptionTranslation({ text: description, source: postLang, target: language })
      triggerUsesTranslation({ text: uses, source: postLang, target: language })
    }
  }, [showTranslation, triggerDescriptionTranslation, triggerUsesTranslation, description, uses, language, postLang]);

  useEffect(() => {
    updateTranslation();
    if (descriptionError) console.error('Description Translation Error:', descriptionError);
    if (usesError) console.error('Uses Translation Error:', usesError);
  }, [descriptionData, usesData, descriptionError, usesError, updateTranslation]);

  const toggleTranslation = () => {
    setShowTranslation((prevShowTranslation) => !prevShowTranslation);
    setTranslatedPost(null); // Reset translated post when toggling
  };

  const handleLanguageChange = (newLanguage: string) => {
    dispatch(setLanguage(newLanguage));
    setShowTranslation(true);
  };

  return (
    <Box display={'flex'} justifyContent={'start'} alignItems={'center'} gap={5}>
      <Menu>
        <MenuButton as={Button} rightIcon={<FaChevronDown />} fontSize={12} mt={2} mb={2}>
          {showTranslation ? 'Change Language' : 'Translate'}
        </MenuButton>
        <MenuList>
          {languages.map((lang) => (
            <MenuItem key={lang.code} onClick={() => handleLanguageChange(lang.code)}>
              {lang.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <Button variant="outline" fontSize={12} colorScheme="#345430" onClick={toggleTranslation} mt={2} mb={2}>
        {showTranslation ? 'View Original' : 'View Translation'}
      </Button>
    </Box>
  );
};

export default TranslationToggle;
