import { useEffect, useRef, useState } from "react";
import {
  Select,
  Button,
  Textarea,
  Input,
  Flex,
  Image,
  Heading,
  VStack,
  useColorMode,
  Box,
  Text,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler, Controller, useFieldArray } from "react-hook-form";
import { ToastNotification } from "../components";
import { addPost } from "../slice/postSlice";
import { FilterOption, RootState, User } from "../types/interface";
import { filterOptions } from "../data/filterType";
import { MdDelete } from "react-icons/md";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { selectAccessToken, selectCurrentUser } from "../slice/userSlice";
import axios from "axios";
import { useNavigate } from "react-router-dom";

interface FormData {
  location: { latitude: number; longitude: number; };
  lang: string;
  name: string;
  description: string;
  uses: string;
  otherNames: { value: string }[];
  images: File[];
  type: string;
}
interface OtherName {
  value: string;
}


const MAX_COUNT = 4;

const CreatePost = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser) as unknown as User
  const language = useSelector((state: RootState) => state.language.language);
  const { colorMode } = useColorMode();
  const [loading, setLoading] = useState(false);
  const [previewImages, setPreviewImages] = useState<string[]>([]);
  const [fileLimit, setFileLimit] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: '',
    status: '',
  });
  const accessToken = useSelector(selectAccessToken); 
  const [otherNames, setOtherNames] = useState<OtherName[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const location = useSelector(
    (state: RootState) => state.reverseGeocode.locationName
  );

  const navigate = useNavigate(); // Add useNavigate hook
  const { handleSubmit, control, register, 
    formState: { isValid, isSubmitting, errors }, reset ,
     setValue,
     getValues } = useForm<FormData>({
    defaultValues: {
      otherNames: [],
      images: [] // initialized as an empty array
    },
    mode: 'onBlur',
    criteriaMode: 'all',
  });
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFiles(Array.from(e.target.files));
    }
  };

  const selectFiles = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setLoading(true);
      const selectedFiles = Array.from(event.target.files);
  
      if (previewImages.length + selectedFiles.length > MAX_COUNT) {
        setLoading(false);
        return;
      }
  
      // Convert File objects to their corresponding URLs
      const fileURLs = selectedFiles.map((file) => URL.createObjectURL(file));
      setPreviewImages((prevImages) => [...prevImages, ...fileURLs]);
  
      // Add selected files to selectedFiles state
      setSelectedFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  
      // Append selected files to form data
      const currentImages = getValues('images') || [];
      setValue('images', [...currentImages, ...selectedFiles]);
  
      // Append otherNames if needed
      setValue('otherNames', otherNames);
  
      checkFileLimit(selectedFiles);
      setLoading(false);
    }
  };
  
  

  const { fields, append, remove } = useFieldArray({
    name: 'otherNames',
    control,
  });

  const filter: FilterOption[] = filterOptions;
  const onSubmit: SubmitHandler<FormData> = async (data) => {
     // Include the location in the form data if it is available
     if (location) {
      data.location = location;
    }

    // console.log("data: ", data)
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append('lang', language); // Language field
      formData.append('name', data.name); // Name field
      formData.append('description', data.description); // Description field
      formData.append('uses', data.uses); // Uses field
      formData.append('type', data.type); // Type field

       // Append location
      if (data.location) {
        formData.append('location', data.location.toString());
      }
      
      // Append otherNames array
      otherNames.forEach((otherName, index) => formData.append(`othernames[${index}]`, otherName.value));
      selectedFiles.forEach((file) => {
        formData.append('files', file);
      });
      
      // console.log("Selected files: ", selectedFiles);
      const response = await axios.post(
        `https://ewe-api.herokuapp.com/api/users/${currentUser.id}/posts`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${accessToken}`, 
            'Access-Control-Allow-Origin': '*/*',
          },
        }
      );
  
      // console.log("Response data: ", response);

      if ('data' in response) {
        // Success response
        const responseData = response.data.data;
        // console.log('Response Data: ', responseData);
        const message = responseData.message || 'Success !!! Post was successfully sent!';
        setToastConfig({ description: message, status: 'success' });
        const post = {
          _id: responseData._id,
          id:responseData._id,
          comments: responseData.comments || [],
          createdAt: responseData.createdAt,
          description: responseData.description,
          dislikes: responseData.dislikes || [],
          images: responseData.images || [],
          lang: responseData.lang,
          likes: responseData.likes || [],
          name: responseData.name,
          otherNames: responseData.otherNames || [], // Ensure 'otherNames' property is present
          type: responseData.type,
          updatedAt: responseData.updatedAt,
          user: responseData.user,
          uses: responseData.uses,
          verify: responseData.verify || false,
        };
        

  
        setToastConfig({
          description: response.data.message || `${responseData.name} Signed In Successfully`,
          status: 'success',
        });
        setShowToast(true);       
        dispatch(addPost(post));
        reset();
        navigate(`/user/${currentUser.id}/posts`);
      } else if ('error' in response) {
        setToastConfig({ description: 'Error in Creating Posts', status: 'error' });
        setShowToast(true);
        setLoading(false);
      }
    } catch (error: any) {
      setToastConfig({ description: error.message || 'An error occurred', status: 'error' });
      setShowToast(true);
      setLoading(false);
    }
  };

  const checkFileLimit = (files: File[]) => {
    if (files.length > MAX_COUNT) {
      setFileLimit(true);
      setToastConfig({ description: `You can only add a maximum of ${MAX_COUNT} files`, status: 'error' });
      setShowToast(true);
    }
  };
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger click event of the file input element
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeIn" }}
      style={{ backgroundColor: colorMode === 'light' ? 'white' : '#345430', color: colorMode === 'light' ? '#345430' : 'white' }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Create Post Page</title>
      </Helmet>
      <motion.div
        style={{ backgroundColor: colorMode === 'light' ? 'white' : '#345430', paddingBottom: "20px" }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5, ease: "easeIn" }}
      >
        <Box fontFamily="Inter" paddingTop={20} marginRight={{ base: "15", md: "20" }} marginLeft={{ base: "15", md: "20" }} pb={20}>
        <Heading fontFamily="Inter" as="h6" fontSize={{ base: '20px', md: "30px" }} textAlign="center">{language === 'en' ? 'Create Post' : 
        (language === 'yo' ? 'E se akosile ìgbékalé eero yin...' : 'Crear Publicación')}</Heading>
          <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data' method="POST">
            <VStack spacing={10} align="center" mt={5} mb={5}>
            <Controller
                control={control}
                name="images"
                render={({ field }) => (
                  <Flex
                    justify="center"
                    align="center"
                    flexDirection="column"
                    borderWidth="2px"
                    borderStyle="dotted"
                    borderColor="gray.300"
                    backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
                    borderRadius={20}
                    p={5}
                    w="full"
                    h="auto"
                  >
                    {!previewImages.length ? (
                      <label>
                        <Flex flexDirection="column" alignItems="center" justifyContent="center">
                          <Box>
                            <AiOutlineCloudUpload
                              size="48px"
                              color={colorMode === 'light' ? 'white' : '#345430'}
                            />
                          </Box>
                          <Text fontSize="lg">Click to upload</Text>
                        </Flex>
                        <Text mt="32px" fontSize="sm" color="black">
                          Recommendation: Use high-quality JPG, JPEG, SVG, PNG, GIF, or TIFF less than 1MB
                          Maximum of four images
                        </Text>
                        <Input
                        {...register("images")}
                        type="file"
                        id="upload-image"
                        onChange={(e) => {
                          selectFiles(e); // Call your selectFiles function to handle file selection
                          field.onChange(e); 
                          handleFileChange(e)
                        }}
                        accept="image/png, application/pdf, image/svg, image/jpeg, image/gif, image/tiff"
                        multiple
                        style={{ display: 'none' }}
                        disabled={fileLimit}
                        ref={fileInputRef}
                      />
                      </label>
                    ) : (
                      <>
                        <Flex flexWrap="wrap">
                          {previewImages.map((imageUrl, index) => (
                            <Box key={index} position="relative" m="2">
                              <Image
                                src={imageUrl}
                                alt={`uploaded-pic-${index}`}
                                h="300px"
                                w="300px"
                                objectFit={'cover'}
                              />
                              <Button
                                type="button"
                                position="absolute"
                                top="0"
                                right="0"
                                p="1"
                                rounded="full"
                                bg="white"
                                fontSize="sm"
                                cursor="pointer"
                                outline="none"
                                _hover={{ boxShadow: 'md' }}
                                onClick={() => setPreviewImages((prevImages) => prevImages.filter((_, i) => i !== index))}
                              >
                                <MdDelete color={'red'} />
                              </Button>
                            </Box>
                          ))}
                        </Flex>
                        {previewImages.length > 0 && (
                          <>
                        <Input
                          {...register("images")}
                          type="file"
                          id="upload-image"
                          onChange={(e) => {
                            selectFiles(e);
                            field.onChange(e); 
                            handleFileChange(e)
                          }}
                          accept="image/png, application/pdf, image/svg, image/jpeg, image/gif, image/tiff"
                          multiple
                          style={{ display: 'none' }}
                          disabled={fileLimit}
                          ref={fileInputRef}
                        />

                            <Button
                              type="button"
                              mt="2"
                              backgroundColor={colorMode === 'light' ? '#345430' : 'white'}
                              color={colorMode === 'light' ? 'white' : '#345430'}
                              size="sm"
                              onClick={handleButtonClick}
                            >
                              Upload Another Image
                            </Button>
                          </>
                        )}
                        <Button
                          type="button"
                          mt="2"
                          backgroundColor={colorMode === 'light' ? '#345430' : 'white'}
                          color={colorMode === 'light' ? 'white' : '#345430'}
                          size="sm"
                          onClick={() => setPreviewImages([])}
                        >
                          Reset
                        </Button>
                      </>
                    )}
                  </Flex>
                )}
              />
              {/* Select Category */}
              <Controller
                control={control}
                defaultValue=""
                name="type"
                render={({ field }) => (
                  <Select
                    {...register("type")}
                    placeholder={language === "en" ? "Select Category..." : (language === 'yo' ? 'Iru Nkan ti e fẹ́ ko...' : 'Seleccionar Categoría...')}
                    {...field}
                    backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
                    borderColor={colorMode === 'light' ? '#345430' : 'white'}
                    fontFamily="Inter"
                    fontSize="20px"
                    _focus={{ borderColor: colorMode === 'light' ? '#345430' : 'white' }}
                    width={"100%"}
                    height={"60px"}
                  >
                    {filter.map((option) => (
                      <option key={option.id} value={typeof option.type === "string" ? option.type : option.type[language]}>
                        <Flex align="center" textTransform={'capitalize'} justifyContent={'space-between'}>
                          <Image src={option.icon} boxSize="100px" p={10} />
                          {typeof option.type === "string" ? option.type : option.type[language]}
                        </Flex>
                      </option>
                    ))}
                  </Select>
                )}
              />
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    {...register("name")}
                    placeholder={language === "en" ? "Please enter title of post/ Odù Ifá..." : (language === 'yo' ? 'ẹ́ ba wa ko ori igbekale yi / Odù Ifá...' : 'Por favor, introduce el título de la publicación/ Odù Ifá...')}
                    {...field}
                    backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
                    borderColor={colorMode === 'light' ? '#345430' : 'white'}
                    fontFamily="Inter"
                    fontSize="20px"
                    _focus={{ borderColor: colorMode === 'light' ? '#345430' : 'white' }}
                    width={"100%"}
                    height={"60px"}
                  />
                )}
              />
              {/* {fields.map((field, index) => (
                <Flex key={field.id} alignItems="center">
          <Controller
                        render={({ field }) => (
                          <Input
                            {...field}
                            placeholder={language === 'en' ? "Other Names/ Other Names Of Odù Ifá...  " : (language === 'yo' ? 'Awọn Oruko Miiran/ Awọn Oruko Miiran Ti Odù Ifá...' : "Otros Nombres/ Otros Nombres Del Odù Ifá...")}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              const updatedOtherNames = [...otherNames];
                              updatedOtherNames[index] = { value: newValue };
                              setOtherNames(updatedOtherNames);
                              field.onChange(e); // Ensure that React Hook Form's internal state is updated
                            }}
                            borderColor={colorMode === 'light' ? '#345430' : 'white'}
                            backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
                            fontFamily="Inter"
                            fontSize="20px"
                            _focus={{ borderColor: colorMode === 'light' ? '#345430' : 'white' }}
                            width={'100%'}
                            height={'60px'}
                            type="text"
                          />
                        )}
                        name={`otherNames.${index}.value` as const}
                        control={control}
                      />

                  <Button type="button" marginLeft={5} onClick={() => remove(index)}>
                    Remove
                  </Button>
                </Flex>
              ))}
              <Button type="button" onClick={() => append({ value: '' })}>
              {language === 'en' ? " Add Other Names " : language === 'yo' ? 'Awọn Oruko Miiran' : "Otros Nombres"}
              </Button> */}

              <VStack align="stretch" spacing="4" width={'100%'}>
                  {fields.map((field, index) => (
                    <Flex key={field.id} alignItems="center">
                      <Controller
                        control={control}
                        name={`otherNames.${index}.value`}
                        render={({ field }) => (
                          <Input
                            {...field}
                            // size="lg"
                            // borderColor={errors.otherNames?.[index]?.value ? "red.500" : "gray.300"}
                            placeholder={language === 'en' ? "Other Names...  " : 
                              (language === 'yo' ? 'Awọn Oruko Miiran...' 
                                : "Otros Nombres...")}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              const updatedOtherNames = [...otherNames];
                              updatedOtherNames[index] = { value: newValue };
                              setOtherNames(updatedOtherNames);
                              field.onChange(e); // Ensure that React Hook Form's internal state is updated
                            }}
                            borderColor={colorMode === 'light' ? '#345430' : 'white'}
                            backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
                            fontFamily="Inter"
                            fontSize="20px"
                            _focus={{ borderColor: colorMode === 'light' ? '#345430' : 'white' }}
                            width={'100%'}
                            height={'60px'}
                            type="text"
                          />
                        )}
                      />
                      <Button
                        ml={2}
                        colorScheme="red"
                        size="sm"
                        onClick={() => remove(index)}
                        height={'60px'}
                      >
                        <MdDelete />
                      </Button>
                    </Flex>
                  ))}
              <Button type="button" onClick={() => append({ value: '' })}>
              {language === 'en' ? " Add Other Names " : language === 'yo' ? 'Awọn Oruko Miiran' : "Otros Nombres"}
              </Button>
                </VStack>

              {/* Description Input */}
              <Controller
                name="description"
                control={control}
                defaultValue=""
                rules={{ maxLength: 350 }}
                render={({ field }) => (
                  <Textarea
                    {...register("description", { maxLength: 800 })}
                    placeholder={language === "en" ? "Description... " : (language === 'yo' ? 'Apejúwe Akole yi...' : "Descripción...")}                   
                    {...field}
                    backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
                    borderColor={colorMode === 'light' ? '#345430' : 'white'}
                    fontFamily="Inter"
                    fontSize="20px"
                    _focus={{ borderColor: colorMode === 'light' ? '#345430' : 'white' }}
                    width={"100%"}
                    aria-invalid={errors.description ? 'true' : 'false'}
                    height={"200px"}
                  />
                )}
              />
            {errors.description && (
              <Text color="red" fontSize="sm">Maximum character limit exceeded</Text> // Display error message
            )}
              <Controller
                name="uses"
                control={control}
                defaultValue=""
                rules={{ maxLength: 350 }}
                render={({ field }) => (
                  <Textarea
                    {...register("uses", { maxLength: 800 })}
                    placeholder={language === "en" ? "Uses..." : (language === 'yo' ? 'Iwulo  Akole yi... ' : 'Usos...')} 
                    {...field}
                    backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
                    borderColor={colorMode === 'light' ? '#345430' : 'white'}
                    fontFamily="Inter"
                    fontSize="20px"
                    _focus={{ borderColor: colorMode === 'light' ? '#345430' : 'white' }}
                    aria-invalid={errors.uses ? 'true' : 'false'}
                    width={"100%"}
                    height={"200px"}
                  />
                )}
              />
          {errors.uses && (
              <Text color="red" fontSize="sm">Maximum character limit exceeded</Text> // Display error message
            )}
              {/* Submit Button */}
              <Button
                isLoading={loading || isSubmitting}
                type='submit'
                loadingText="Posting..."
                backgroundColor={colorMode === 'light' ? "#345430" : "white"}
                color={colorMode === 'light' ? 'white' : '#345430'}
                size="lg"
                width={'full'}
                disabled={!isValid}
                marginTop={'10px'}
              >
                Submit
              </Button>
              {/* Toast Notification */}
              {showToast && (
                <ToastNotification
                  description={toastConfig.description}
                  status={toastConfig.status}
                  onClose={() => setShowToast(false)}
                />
              )}
            </VStack>
          </form>
        </Box>
      </motion.div>
    </motion.div>
  );
};

export default CreatePost;