import { Button, Box, Flex, Icon } from '@chakra-ui/react';
import { FaTwitter, FaFacebook, FaWhatsapp, FaLink } from 'react-icons/fa';
import { useState } from 'react';
import ToastNotification from './ToastNotification';

interface ShareButtonProps {
  postUrl: string;
}

const ShareButton: React.FC<ShareButtonProps> = ({ postUrl }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: "",
    status: "",
  });

  const sharePost = (platform: string) => {
    if (platform === 'Twitter') {
      const tweetUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(postUrl)}&hashtags=Ewé,YorubaPlants`;
      window.open(tweetUrl, '_blank');
    } else if (platform === 'Facebook') {
      const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(postUrl)}`;
      window.open(facebookUrl, '_blank');
    } else if (platform === 'WhatsApp') {
      const whatsappUrl = `whatsapp://send?text=${encodeURIComponent(postUrl)}`;
      window.location.href = whatsappUrl;
    }
  };

  const copyLink = () => {
    navigator.clipboard.writeText(postUrl)
      .then(() => {
        setToastConfig({ description: 'Link copied to clipboard', status: 'success' });
        setShowToast(true);
      })
      .catch((error: string) => {
        setToastConfig({ description: 'Failed to copy link', status: error });
        setShowToast(true);
      });
  };

  return (
    <Box
      position="absolute"
      top="100%"
      zIndex="999"
      backgroundColor="white"
      border="1px solid #ccc"
      borderRadius="4px"
      boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
    >
      <Flex flexDirection="column" p="2">
        <Button
          variant="ghost"
          color="#345430"
          leftIcon={<Icon as={FaTwitter} />}
          onClick={() => sharePost('Twitter')}
        >
          Twitter
        </Button>
        <Button
          variant="ghost"
          color="#345430"
          leftIcon={<Icon as={FaFacebook} />}
          onClick={() => sharePost('Facebook')}
        >
          Facebook
        </Button>
        <Button
          variant="ghost"
          color="#345430"
          leftIcon={<Icon as={FaWhatsapp} />}
          onClick={() => sharePost('WhatsApp')}
        >
          WhatsApp
        </Button>
        <Button
          variant="ghost"
          color="#345430"
          leftIcon={<Icon as={FaLink} />}
          onClick={copyLink}
        >
          Copy Link
        </Button>
      </Flex>
      {showToast && (
        <ToastNotification
          description={toastConfig.description}
          status={toastConfig.status}
          onClose={() => setShowToast(false)}
        />
      )}
    </Box>
  );
};

export default ShareButton;
