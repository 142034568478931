import React, { useState, useEffect } from 'react';
import { Box, Button, Text, useColorMode } from '@chakra-ui/react';
import { useCommentOnArticleMutation } from '../../api/articles';
import { useSelector } from 'react-redux';
import { selectCurrentUser, selectIsAuthenticated } from '../../slice/userSlice';
import { useParams, useNavigate } from 'react-router-dom';
import { Articles, User } from '../../types/interface';
import FormInput from '../Form/FormInput';
import { SubmitHandler, useForm } from 'react-hook-form';
import ToastNotification from '../ToastNotification';
import { Link } from 'react-router-dom';

interface CommentComponentProps {
  article?: Articles;
  updatedArticleData: any;
}

interface CommentFormData {
  comment: string;
}

const ArticleComment: React.FC<CommentComponentProps> = ({ article, updatedArticleData }) => {
  const [comments, setComments] = useState<{ user: any; username: string; comment: string }[]>([]);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const currentUser = useSelector(selectCurrentUser) as User;
  const [refreshFlag, setRefreshFlag] = useState(false);
  const { userId } = useParams();
  const validUserId = userId || currentUser?.id;
  const navigate = useNavigate(); // Add useNavigate hook
  const [commentMutation] = useCommentOnArticleMutation();
  const { colorMode } = useColorMode();
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: "",
    status: "",
  });

  const { control, register, handleSubmit, formState: { errors, isSubmitting, isValid }, reset } = useForm<CommentFormData>({
    criteriaMode: 'all',
  });
  const onSubmit: SubmitHandler<CommentFormData> = async (data) => {
    if (!isAuthenticated || !article?._id || data.comment.trim() === '') return;
  
    const newCommentData = { user: currentUser, username: currentUser.username, comment: data.comment };
  
    const comment = {
      comment: data.comment
    };
  
    // Call the commentMutation function to send the comment to the backend
    commentMutation({ userId: validUserId, articleId: article._id, comment })
      .then(() => {
        setRefreshFlag(prev => !prev); // Update refresh flag to trigger re-render
        const updatedComments = [...comments, newCommentData];
        setComments(updatedComments);
        setToastConfig({
          description: `Comment Added Successfully`,
          status: 'success',
        });
        setShowToast(true);
        navigate(`/user/${validUserId}/articles/${article._id}`); // Navigate to article page
        reset();
        window.location.reload(); 
      })
      .catch((error) => {
        console.error("Error: ", error);
        setToastConfig({
          description: `Failed to add comment: ${error.message}`,
          status: 'error',
        });
        setShowToast(true);
      });
  };

  useEffect(() => {
    // Fetch existing comments for the article and set them in the state
    if (article && article.comments) {
      const transformedComments = article.comments.map(comment => ({
        user: comment.user,
        username: comment.user.username,
        comment: comment.comment
      }));
      setComments(transformedComments);
    }
  }, [article, refreshFlag]); // Include refreshFlag in dependencies
  

  return (
    <Box>
      {comments.map((comment, index) => (
        <Box key={index} mt={5}>
                 {isAuthenticated ?  
                  <Link to={`/user/${comment?.user?._id}`}>
                    <Text fontSize={'16px'} fontWeight="bold">@{comment?.user?.username}</Text> 
                    </Link>
                : <Link to={'/login'}/>}
          <Text fontSize={'16px'} >{comment.comment}</Text>
        </Box>
      ))}
      <Box>
        {isAuthenticated && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput
            name="comment"
            label="Add a Comment"
            control={control}
            rules={{ required: 'Comment is required', maxLength: 200 }}
            errors={errors}
            type="text"
            text=''
            register={register}
            aria-invalid={errors.comment ? 'true' : 'false'}
          />
           {errors.comment && (
              <Text color="red" fontSize="sm">Maximum character limit exceeded</Text> // Display error message
            )}
            <Button
              type="submit"
              isLoading={isSubmitting}
              loadingText="Adding Comments in..."
              backgroundColor={colorMode === 'light' ? '#345430' : "white"}
              color={colorMode === 'light' ? "white" : '#345430'}
              size="lg"
              width={'full'}
              disabled={!isValid}
              mt={2}
            >
              Submit
            </Button>
          </form>
        )}
      </Box>
      {showToast && (
              <ToastNotification
                description={toastConfig.description}
                status={toastConfig.status}
                onClose={() => setShowToast(false)}
              />
            )}
    </Box>
  );
};

export default ArticleComment;
