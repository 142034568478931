// api.tsx
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../types/interface';
import { selectAccessToken } from '../slice/userSlice';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const AuthCall = () => {
  const authSlice = createApi({
    reducerPath: 'auth',
    baseQuery: fetchBaseQuery({
      baseUrl: BASE_URL,
      prepareHeaders: (headers, { getState }) => {
        const state = getState() as RootState | undefined;
        const accessToken = state ? selectAccessToken(state) : '';
        if (accessToken) {
          headers.set('Authorization', `Bearer ${accessToken}`);
        }
        headers.set('Content-Type', 'application/json');
        headers.set('Access-Control-Allow-Origin', '*');
      },
    }),
    tagTypes: ['SignIn', 
    'SignUp', 
    'ForgotPassword', 
    'Update', 
    'UserProfile', 
    'AccessToken', 
    'ResetPassword', 
    "ChangePassword", 
    "Google", 
    "Facebook",
    "Logout",
    'Posts'],
    endpoints: (builder) => ({
      signIn: builder.mutation({
        query: (credentials) => ({
          url: 'auth/signin',
          method: 'POST',
          body: credentials,
          providesTags: ['SignIn'],
          defer: true, 
        }),
      }),
      signUp: builder.mutation({
        query: (userInfo) => ({
          url: 'auth/signup',
          method: 'POST',
          body: userInfo,
          providesTags: ['SignUp'],
          defer: true, 
        })
      }),
      logout: builder.mutation({
        query: (userId) => ({
          url: 'auth/logout',
          defer: true,
          method: 'POST',
          body: userId,
          providesTags: ['Logout'],
        }),
      }),
      resetPassword: builder.mutation({
        query: (resetInfo) => ({
          url: 'auth/resetpassword',
          method: 'POST',
          body: resetInfo,
          providesTags: ['ResetPassword'],
          defer: true, 
        })
      }),    
      changePassword: builder.mutation({
        query: (password) => ({
          url: 'auth/changepassword',
          method: 'POST',
          body: password,
          providesTags: ['ChangePassword'],
          defer: true, 
        })
      }),
      validateResetToken: builder.query({
        query: ({ tokenId, userId }) => {
          return `auth/resetpassword/${tokenId}/${userId}`;
        },
      }),
       subscribeToNewsletter: builder.mutation({
        query: (email) => ({
          url: '/subscribe', // Replace 'subscribe-to-newsletter' with the actual API endpoint for subscribing to the newsletter
          method: 'POST',
          body: email ,
          providesTags: ['SubscribeToNewsletter'],
          defer: true,
        }),
      }),
    }),
  });

  // Export the created hooks/selectors from the apiSlice
  const { 
    useSignInMutation, 
    useSignUpMutation, 
    useSubscribeToNewsletterMutation,
    useChangePasswordMutation,
    useResetPasswordMutation,
    useLogoutMutation,
    useValidateResetTokenQuery } = authSlice;


  const selectSignInResult = authSlice.endpoints.signIn.select('');
  const selectSignUpResult = authSlice.endpoints.signUp.select('');

  // Export the selector for signed-in user
  const selectSignedInUser = (state: any) => {
    const signInData = selectSignInResult(state).data;
    const signUpData = selectSignUpResult(state).data;
    return signInData || signUpData;
  };

  return {
    useSignInMutation,
    useSignUpMutation,
    useSubscribeToNewsletterMutation,
    selectSignedInUser,
    authSlice,
    useChangePasswordMutation,
    useResetPasswordMutation,
    useLogoutMutation,
    useValidateResetTokenQuery
  };
};
export default AuthCall;