import React, { useState, useEffect } from "react";
import { Box, useColorMode } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { AutoBackButton, SearchInput, SearchResults, ToastNotification } from "../components";
import { Helmet } from "react-helmet";
import { SearchResult, User } from "../types/interface";
import { selectCurrentUser } from "../slice/userSlice";
import { useSelector } from "react-redux";
import { useGetAllPostsQuery } from "../api/postApi";
import { useGetArticleSearchQueryQuery } from "../api/articles";

const normalizeString = (str: string) => {
  if (str) {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  }
  return "";
};

const inHouseSearch = (data: any[], query: string) => {
  const normalizedQuery = normalizeString(query);
  return data.filter(item => {
    const normalizedName = normalizeString(item?.name || "");
    const normalizedTitle = normalizeString(item?.title || "");
    return normalizedName.includes(normalizedQuery)  || normalizedTitle.includes(normalizedQuery);
  });
};

const SearchPage = () => {
  const currentUser = useSelector(selectCurrentUser) as unknown as User;
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const location = useLocation();
  const queryParam = new URLSearchParams(location.search).get("query") || "";
  const { data: posts, isLoading: postLoading, isError: postsError } = useGetAllPostsQuery({});
  const { colorMode } = useColorMode();
  
  const {
    data: articleData,
    error: articleError,
    isLoading: articleLoading,
    refetch: articleRefresh
  } = useGetArticleSearchQueryQuery({
    limit: 10,
    page: 1,
    search: searchQuery || "",
  });

  const loading = articleLoading || postLoading
  // console.log("Search: ", searchResults);
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: "",
    status: "",
  });

  useEffect(() => {
    if (postsError) {
      setToastConfig({ description: 'Searching Failed', status: 'error' });
      setShowToast(true);
    }
  }, [postsError]);

  useEffect(() => {
    if (articleError) {
      setToastConfig({ description: 'Searching Failed', status: 'error' });
      setShowToast(true);
    }
  }, [articleError]);

  const handleSearch = (query: React.SetStateAction<string>) => {
    setSearchQuery(query);
    articleRefresh();
  };

  useEffect(() => {
    const allPosts = posts?.data?.posts;
    const allArticles = articleData?.data?.articles;
  
    if (allPosts && allArticles) {
      // Combine posts and articles into a single array
      const combinedResults: SearchResult[] = [
        ...allPosts.map((post: any) => ({ ...post, type: "post" })),
        ...allArticles.map((article: any) => ({ ...article, type: "article"})),
      ];
  
      // Filter the combined results based on the search query
      const filteredResults = inHouseSearch(combinedResults, searchQuery);
      setSearchResults(filteredResults);
    }
  }, [posts, articleData, searchQuery]);
  
  
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeIn" }}
      style={{
        backgroundColor: colorMode === "light" ? "#E0EAE0" : "#588552",
        color: colorMode === "light" ? "#345430" : "white",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ewe Search: {queryParam}</title>
      </Helmet>
      <Box
        height={"100%"}
        style={{
          background: colorMode === "light" ? "#E0EAE0" : "#588552",
          borderRadius: "20px",
        }}
        paddingTop={20}
        marginRight={{ base: "15", md: "20" }}
        marginLeft={{ base: "15", md: "20" }}
        pb={20}
      >
        <AutoBackButton />
        <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <SearchInput
            backgroundColor={colorMode === "light" ? "#E0EAE0" : "#588552"}
            query={searchQuery}
            updateQuery={handleSearch}
            isLoading={loading}
            onSubmit={handleSearch}
          />
        </div>
        <SearchResults 
          loading={loading} 
          results={searchResults}
          currentUser={currentUser} />
      </Box>
      {showToast && (
        <ToastNotification
          description={toastConfig.description}
          status={toastConfig.status}
          onClose={() => setShowToast(false)}
        />
      )}
    </motion.div>
  );
};

export default SearchPage;
